<template>
  <div class='main'>
    <div class='main__header'>
      <div class='main__header_logo'></div>
      <div class='main__header_title'>Сделаем добро заметнее</div>
      <div class='main__header_buttons'>
        <div class='main__header_buttons_label'>Скачай приложение Karma Plus и начни помогать людям, повышая свою карму</div>
        <div class="main__header_buttons_container">
          <button class='main__header_buttons_container_button'><span class="android" style="background: white;"></span> <span class="button_text">Karma Plus для Android</span></button>
          <button class='main__header_buttons_container_button'><span class="IOS" style="background: white;"></span><span class="button_text">Karma Plus дла IPhone / IPad</span></button>
        </div>
      </div>
      <!--<img class="main__header_backgroundHeaderMini" src="./assets/backgroundHeaderMini.png"/>-->
      <!--<div class='main__header_blur_bottom'></div>-->
      <!--<div class='main_header_treangel_one'></div>-->
    </div>
    <div class="main__header_miniScreen">
      <div class='main__header_buttons'>
        <div class='main__header_buttons_label'>Скачай приложение Karma Plus и начни помогать людям, повышая свою карму</div>
        <div class="main__header_buttons_container">
          <button class='main__header_buttons_container_button'><span class="android" style="background: white;"></span> <span class="button_text">Karma Plus для Android</span></button>
          <button class='main__header_buttons_container_button'><span class="IOS" style="background: white;"></span><span class="button_text">Karma Plus дла IPhone / IPad</span></button>
        </div>
      </div>
    </div>
    <div class='main__phone'>
      <img src="./assets/phone.png"/>
    </div>
    <div class='main__about'>
      <!--<div class="main__about_white"></div>-->
      <div class='main__about_container'>
        <div class='main__about_container_title'>Karma Plus</div>
        <div class='main__about_container_desciption'>
          <p>Мы группа неравнодушных людей, которые верят, что мир можно сделать лучше и добрее с помощью технологий и идеи, которая объединит нас. Поэтому мы создали приложение Karma Plus в котором есть возможность помогать друг другу.</p>
          <p>Наша идея в том, чтобы создать общество людей, для которых доброта и помощь ближнему — это стиль жизни.</p>
          <p>В основе нашей идеи лежит естественная потребность человека к взаимопомощи и к комфортной жизни в социуме. Мы создаём сообщество, всегда готовых поддержать и не стесеяющихся попросить о помощи.</p>
        </div>
      </div>
    </div>
    <div class='main__photos'>
      <div class='main__photos_phone'></div>
      <div class='main__photos_logo'></div>
    </div>
    <div class='main__mission'>
      <div class='main__mission_title'>Миссии приложения</div>
      <div class='main__mission_container'>
        <div class='main__mission_container_box'>
          <img src="./assets/MissionPeople.png" />
          <div>Делать общество лучше и сплоченнее</div>
        </div>
        <div class='main__mission_container_box'>
          <img src="./assets/MissionStatus.png" />
          <div>Формируем социальный рейтинг</div>
        </div>
        <div class='main__mission_container_box'>
          <img src="./assets/MissionHand.png" />
          <div>Повышаем уровень взаимопомощи</div>
        </div>
      </div>
    </div>
    <div class='main__advantages'>
      <div class='main__advantages_title'>Как мы делаем мир лучше</div>
      <div class="main__advatages_container">
        <div class="main__advatages_container_box">
          <div class="main__advatages_container_box_text_box">
            <div class="main__advatages_container_box_title">Оценивай пользователей</div>
            <div class="main__advatages_container_box_descrition">В приложении есть возможность повышать или понижать карму пользователей. Только учитывайте, при понижении кармы человека, вы понижаете карму и себе.</div>
          </div>
          <div class="main__advatages_container_box_img_box right">
            <img src="./assets/plus.jpg"/>
          </div>
        </div>
        <div class="main__advatages_container_box">
          <div class="main__advatages_container_box_img_box">
            <img src="./assets/radar.jpg"/>
          </div>
          <div class="main__advatages_container_box_text_box">
            <div class="main__advatages_container_box_title">Помогай людям и проси о помощи сам</div>
            <div class="main__advatages_container_box_descrition">В приложении можно увидеть пользователей рядом с тобой, которым прямо сейчас нужна твоя помощь. Также ты сам можешь оставлять объявления и твоя просьба не останется без внимания.</div>
          </div>
        </div>
        <div class="main__advatages_container_box">
          <div class="main__advatages_container_box_text_box">
            <div class="main__advatages_container_box_title">Делай пожертвования</div>
            <div class="main__advatages_container_box_descrition">Можешь сделать пожертвование в любой благотворительный фонд, размещенный в приложении. Все фонды являются официальными.</div>
          </div>
          <div class="main__advatages_container_box_img_box right">
            <img src="./assets/help.jpg"/>
          </div> 
        </div>
        <div class="main__advatages_container_box">
          <div class="main__advatages_container_box_img_box">
            <img src="./assets/rating.jpg"/>
          </div>
          <div class="main__advatages_container_box_text_box">
            <div class="main__advatages_container_box_title">Участвуй в рейтинге пользователей</div>
            <div class="main__advatages_container_box_descrition">Положительные действия формируют твой показатель Кармы. Чем выше карма, тем выше находишься в рейтинге своего города, страны и мира.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="main__footer">
      <div class="main__footer_box">
        <div class='main__footer_logo'></div>
        <div class="main__footer_label">Скачай приложение <br /> и вступи в сообщество будущего</div>
        <div class='main__footer_buttons'>
          <button class='main__footer_button'><span class="android" style="background: black;"></span> <span class="button_text">Karma Plus для Android</span></button>
          <button class='main__footer_button'><span class="IOS" style="background: black;"></span><span class="button_text">Karma Plus для IPhone / IPad</span></button>
        </div>
      </div>
      <div class="main__footer_info">
        <span>© Karma Plus 2022</span>
        <span style="font-size: 25px;">18+</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
</style>
